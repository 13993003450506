<script>
import moment from "moment";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import { mapGetters } from "vuex";
import tabIcon from "@/assets/narvarostatistik.svg";
import StatistikSearchBar from "@/tabs/Skreg/StatistikSearchBar.vue";
import ActionIconOpanmalan from "@/assets/actionIcons/show.svg";
import NarvarostatistikTableRow from "@/tabs/Skreg/Statistik/NarvarostatistikTableRow.vue";
import JournalAnteckning from "@/tabs/Journalanteckningar/Journalanteckning.vue";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";

import { fetchData } from "@/tabs/Operation/utils";
import KlinikenPopup from "@/components/Popup/KlinikenPopup.vue";
import { cloneDeep } from "lodash";

import IdrottPopup from "@/tabs/Skreg/Statistik/IdrottPopup.vue";

export default {
    extends: BaseTab,
    name: "NarvarostatistikSpelare",
    tabName: "Närvarostatistik spelare",
    icon: tabIcon,
    patientBound: true,

    components: {
        KlinikenPopup,
        StatistikSearchBar,
        NarvarostatistikTableRow,
        ModalBasic,
        IdrottPopup,
    },

    props: {
        parameters: {
            type: Object,
        },
        index: {
            type: Number,
        },
    },

    data() {
        return {
            searchOptions: {},
            searchFilterValues: null,
            headers: [
                "Deltagare",
                "Totalt",
                "Totalt (h)",
                "Deltagit",
                "Deltagit (h)",
                "Frånvarande",
                "Frånvarande (h)",
                "Skadad",
                "Skadad (h)",
                "Frånvarande (%)",
                "Skadad (%)",
                "Status",
                "",
            ],

            isAllRowsShown: false,
            dropdownActions: [
                {
                    title: "Journalanteckningar skadekort",
                    name: "journalanteckningarskadekort",
                    icon: ActionIconOpanmalan,
                    show: (item) => !!item.data.sista_oavstamd_skadekort,
                },
            ],
            rowData: {},
            BASE_URL: "/skreg/statistik/",
            lagDropdownOptions: [],
            idrotter: [],
            idrott: null,
            spelare: null,
            showNoDataMessage: false,
        };
    },

    computed: {
        ...mapGetters("patientData", ["patient"]),
    },

    methods: {
        setSpelareOptions(arr) {
            this.lagDropdownOptions = arr.map((item) => {
                return item.lag_display;
            });
        },
        async getIdrotter(arr) {
            const array = arr.map((item) => {
                return item.lag_display.idrott_display.namn;
            });
            const _idrotter = array.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            if (_idrotter.length > 0) {
                this.idrotter = _idrotter.map((name) => {
                    return { label: name, value: name };
                });
            }
            if (_idrotter.length > 1) {
            } else {
                this.idrott = _idrotter[0];
            }
        },
        async getLag() {
            return await fetchData({
                url: `/skreg/spelare/${this.returnValueifNotUndefined(this.idrott)}`,
            });
        },
        async dropdownaction({ action, item }) {
            switch (action) {
                case "journalanteckningarskadekort": {
                    let id = item.data.sista_oavstamd_skadekort;
                    await this.$store.dispatch("tabs/closeTab", JournalAnteckning, { root: true });
                    await this.$store.dispatch("journalanteckningar/getJournalanteckning", id);
                    let anteckning = this.$store.getters["journalanteckningar/anteckning"];
                    await this.$store.dispatch("patientData/loadFromBackend", { id: anteckning.patient.id });
                    await this.$store.dispatch("tabs/openTab", { component: JournalAnteckning });
                    break;
                }
                default:
                    break;
            }
        },
        async searchLag(query_parameters) {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            let url_query_parameters = `?lag=${this.returnValueifNotUndefined(
                this.searchFilterValues?.dropdownOption
            )}&tidpunkt__date__gte=${this.returnValueifNotUndefined(
                this.searchFilterValues?.fromDate
            )}&tidpunkt__date__lte=${this.returnValueifNotUndefined(this.searchFilterValues?.toDate)}`;
            await this.getSpelareData(url_query_parameters);
            var data = cloneDeep(this.rowData);
            if (Object.keys(data.data).length > 0) {
                this.showNoDataMessage = false;
            } else {
                this.showNoDataMessage = true;
            }
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
        async chooseIdrott(idrott) {
            this.idrott = idrott;
            let url_query_parameters = `?lag=${this.returnValueifNotUndefined(
                this.searchFilterValues?.dropdownOption
            )}&tidpunkt__date__gte=${this.returnValueifNotUndefined(
                this.searchFilterValues?.fromDate
            )}&tidpunkt__date__lte=${this.returnValueifNotUndefined(this.searchFilterValues?.toDate)}`;
            await this.getSpelareData(url_query_parameters);
        },
        returnValueifNotUndefined(value) {
            return value !== undefined && value !== null && value !== "allalag" ? value : "";
        },
        async fetchSpelare(query_url) {
            this.spelare = await fetchData({
                url: `skreg/spelare/${this.patient.id}`,
            });
            if (this.spelare.spelarelag?.length > 0) {
                this.setSpelareOptions(this.spelare.spelarelag);
                await this.getIdrotter(this.spelare.spelarelag);
                if (this.idrott) {
                    this.rowData = await fetchData({
                        url:
                            `/skreg/statistik/idrotter/${this.idrott}/narvaro/spelare/${this.spelare.id}/` +
                            this.returnValueifNotUndefined(query_url),
                    });
                }
            } else {
                this.setSpelareOptions([]);
                await this.getIdrotter([]);
                await this.$refs.dialog.show({
                    variant: BasicModalVariant.WARNING,
                    title: "Spelaren är inte inlagd i något lag.",
                    okButton: "OK",
                });
                this.$store.dispatch("tabs/closeTab", this.tabId);
            }
            this.lagDropdownOptions.push({
                namn: "Alla lag",
                id: "allalag",
                idrott_display: {
                    id: "idrott_display_allalag",
                    namn: "Alla lag",
                },
            });
            this.showNoDataMessage = !this.spelare.spelarelag.length;
        },
        async getSpelareData(query_url) {
            this.rowData = await fetchData({
                url:
                    `/skreg/statistik/idrotter/${this.idrott}/narvaro/spelare/${this.spelare.id}/` +
                    this.returnValueifNotUndefined(query_url),
            });
            this.lagDropdownOptions = this.lagDropdownOptions.filter(
                (item) => item.idrott_display.namn === this.idrott
            );
            if (this.searchFilterValues.dropdownOption !== "allalag") {
                this.rowData.spelare.spelarelag = this.rowData.spelare.spelarelag.filter(
                    (item) => item.lag === this.searchFilterValues.dropdownOption
                );
            }
            this.lagDropdownOptions.push({
                namn: "Alla lag",
                id: "allalag",
                idrott_display: {
                    id: "idrott_display_allalag",
                    namn: "Alla lag",
                },
            });
        },
        showNoData() {
            this.showNoDataMessage = !(this.rowData?.data && Object.keys(this.rowData.data).length > 0);
        },
    },
    async created() {
        const firstDate = moment().startOf("month");
        const lastDate = moment().endOf("month");
        this.searchFilterValues = {
            fromDate: firstDate.format("YYYY-MM-DD"),
            toDate: lastDate.format("YYYY-MM-DD"),
        };
        let url_query_parameters = `?tidpunkt__date__gte=${firstDate.format(
            "YYYY-MM-DD"
        )}&tidpunkt__date__lte=${lastDate.format("YYYY-MM-DD")}`;
        await this.fetchSpelare(url_query_parameters);
        this.idrott && (await this.getSpelareData(url_query_parameters));
        this.showNoData();
    },
};
</script>

<template>
    <div class="narvarostatistik-spelare">
        <h2>Närvarostatistik spelare</h2>
        <StatistikSearchBar
            v-model="searchFilterValues"
            :showLagDropdown="true"
            :lag="lagDropdownOptions"
            @emitValues="searchLag"
        />
        <div v-if="!showNoDataMessage" class="antal_aktiviteter_deltagande">
            <h4>
                Antal förekomster av partiellt deltagande:
                <span>{{ rowData.data?.antal_aktiviteter_med_delvis_deltagande }}</span>
            </h4>
            <table>
                <thead class="header">
                    <tr>
                        <th class="" v-for="(header, index) in headers" :key="index">
                            <div
                                v-if="header === 'Deltagare'"
                                class="header-vertical-padding-8align-left heading-style padding-left-20"
                            >
                                {{ header }}
                            </div>
                            <div v-else class="header-vertical-padding-8 heading-style align-right">
                                {{ header }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <NarvarostatistikTableRow
                    :rowData="rowData"
                    :index="index"
                    :dropdownActions="dropdownActions"
                    :isAllNestedRowsShown="true"
                    @action="dropdownaction"
                />
            </table>
        </div>
        <div v-else class="no-data">
            <img src="@/assets/check_circle_black.svg" alt="black circle" /> Det finns inga avstämda aktiviteter i vald
            tidsperiod.
        </div>
        <IdrottPopup :idrotter="idrotter" @chooseIdrott="chooseIdrott" />
        <modal-basic ref="dialog" />
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/variables";

.narvarostatistik-spelare {
    .no-data {
        img {
            height: 20px;
            margin-right: 10px;
        }

        display: flex;
    }

    .antal_aktiviteter_deltagande {
        margin: 2rem 0;

        h4 {
            margin: 0;
        }
    }

    .padding-left-20 {
        padding-left: 1.25rem;
    }

    .align-right {
        text-align: right;
    }

    .header-vertical-padding-8 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    table {
        border-collapse: collapse;
        width: 100%;

        thead {
            tr {
                color: $color-text-black;
                line-height: 1rem;
                font-size: 14px;
            }

            background-color: $color-bg-table-header;

            .heading-style {
                color: $color-table-header;
                font-family: Roboto Medium;
                font-weight: 500;
                line-height: 17px;
                font-size: 14px;
            }
        }
    }
}
</style>
