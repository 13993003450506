<script>
import tabIcon from "@/assets/narvarostatistik.svg";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import { mapActions, mapGetters } from "vuex";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlue from "@/assets/plus_circle_blue.svg";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import FormAktivitet from "@/tabs/Skreg/Aktiviter/FormAktivitet.vue";
import dialogEvents from "@/dialogEvents";
import { openDialog } from "@/utils";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import moment from "moment";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "lag",
        title: "Lag",
        template: (item) => {
            return noNull(item.lag_display.namn);
        },
    },
    {
        name: "plats",
        title: "Plats",
        template: (item) => {
            if (item.plats_display !== null) return noNull(item.plats_display.namn);
            return "";
        },
    },
    {
        name: "datum",
        title: "Datum",
        template: (item) => {
            return noNull(item.datum);
        },
    },
    {
        name: "tid",
        title: "Tid",
        template: (item) => {
            return noNull(item.tid);
        },
    },
    {
        name: "längd",
        title: "Längd",
        template: (item) => {
            return noNull(`${item.langd}`);
        },
    },
    {
        name: "typ",
        title: "Typ av aktivitet",
        template: (item) => {
            return noNull(item.aktivitetstyp_display.namn);
        },
    },
    {
        name: "underlag",
        title: "Underlag",
        template: (item) => {
            return noNull(item.underlag_display.namn);
        },
    },
    {
        name: "beskrivning",
        title: "Beskrivning",
        template: (item) => {
            return noNull(item.beskrivning);
        },
    },
    {
        name: "status",
        title: "Status",
        template: (item) => {
            return noNull(item.status);
        },
    },
];

const TABLE_ACTIONS = [
    {
        name: "edit",
        title: "Stäm av närvaro",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
    {
        name: "delete",
        title: "Radera aktivitet",
        img: "delete.svg",
        condition: () => {
            return true;
        },
    },
    {
        name: "duplicate",
        title: "Duplicera aktivitet",
        img: "duplicate.svg",
        condition: () => {
            return true;
        },
    },
];

// bör denna tillhöra global state istället? påverkar limit
var numberOfBatchesToShow = 1;

export default {
    extends: BaseTab,
    icon: tabIcon,
    tabName: "Aktiviteter",
    name: "ListAktiviteter",

    components: {
        HollowButton,
        CustomActionTable,
    },

    data() {
        return {
            PlusCircleBlue,
            TableColumns: TABLE_COLUMNS,
            TableActions: TABLE_ACTIONS,
            formData: {
                tidpunkt__date__gte: "",
                tidpunkt__date__lte: "",
            },
        };
    },

    computed: {
        ...mapGetters("skreg/aktiviteter", ["aktiviteter", "count", "limit"]),
    },

    methods: {
        ...mapActions("skreg/aktiviteter", ["load", "loadMore"]),
        async addAktivitet() {
            await this.$store.dispatch("tabs/closeTab", FormAktivitet);
            await this.$store.dispatch("tabs/openTab", { component: FormAktivitet });
        },
        async updateAktivitet(aktivitet) {
            await this.$store.dispatch("tabs/closeTab", FormAktivitet);
            await this.$store.dispatch("tabs/openTab", {
                component: FormAktivitet,
                parameters: { aktivitet: aktivitet },
            });
        },
        getDefaultFilterParams() {
            const gte = moment().subtract(10, "days").format("YYYY-MM-DD");
            const lte = moment().add(10, "days").format("YYYY-MM-DD");

            return {
                tidpunkt__date__gte: gte,
                tidpunkt__date__lte: lte,
            };
        },
        async filter(data) {
            for (var key in data) {
                if (data[key].length === 0) delete data[key];
            }
            if (!Object.keys(data).length) {
                this.$store.commit("skreg/aktiviteter/resetFilterParams");
            } else {
                this.$store.commit("skreg/aktiviteter/setFilterParams", data);
            }
            this.$store.commit("skreg/aktiviteter/resetLimit");
            numberOfBatchesToShow = 1;
            await this.load();
        },
        deleteAktivitetDialog(aktivitet) {
            if (aktivitet.status === "Avstämd") {
                openDialog("Denna aktivitet är avstämd och kan inte raderas.", "error");
            } else {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Vill du verkligen radera denna aktivitet?",
                    buttons: [
                        {
                            title: "Nej",
                            type: "primary",
                        },
                        {
                            title: "Ja",
                            type: "secondary",
                            action: async () => {
                                const avstamningar = await this.fetchAvstamningar(aktivitet);

                                if (avstamningar.some((a) => a.journalanteckning)) {
                                    openDialog(
                                        "Denna aktivitet innehåller kopplingar till skadekort och kan inte raderas.",
                                        "error"
                                    );
                                } else {
                                    // Om inga skadekort - kolla om någon spelare är avstämd
                                    if (avstamningar.some((a) => a.status !== "Ej avstämd")) {
                                        dialogEvents.$emit("open", {
                                            type: "warning",
                                            description:
                                                "Du kommer förlora registrerad närvaro på denna aktivitet. Vill du radera ändå?",
                                            buttons: [
                                                {
                                                    title: "Nej",
                                                    type: "primary",
                                                },
                                                {
                                                    title: "Ja",
                                                    type: "secondary",
                                                    action: async () => {
                                                        await this.$store.dispatch(
                                                            "skreg/aktiviteter/delete",
                                                            aktivitet
                                                        );
                                                    },
                                                },
                                            ],
                                        });
                                    } else {
                                        // Alla har status Ej avstämd
                                        await this.$store.dispatch("skreg/aktiviteter/delete", aktivitet);
                                    }
                                }
                            },
                        },
                    ],
                });
            }
        },
        async fetchAvstamningar(aktivitet) {
            try {
                const response = await klinikenApi.get(`skreg/aktiviteter/${aktivitet.id}/avstamningar/`, getConfig());
                return response.data;
            } catch (e) {
                openDialog(getErrorMessage(e), error);
                return [];
            }
        },
        async loadMoreAktiviteter() {
            numberOfBatchesToShow += 1;
            await this.$store.dispatch("skreg/aktiviteter/loadMore", numberOfBatchesToShow);
        },
        async duplicateAktivitet(aktivitet) {
            await this.addAktivitet();
            await this.$store.dispatch("tabs/closeTab", FormAktivitet);
            await this.$store.dispatch("tabs/openTab", {
                component: FormAktivitet,
                parameters: {
                    aktivitet: aktivitet,
                    duplicate: true,
                },
            });
        },
    },

    created() {
        const defaultPeriodDates = this.getDefaultFilterParams();
        this.formData.tidpunkt__date__gte = defaultPeriodDates.tidpunkt__date__gte;
        this.formData.tidpunkt__date__lte = defaultPeriodDates.tidpunkt__date__lte;
        this.load(this.formData);
        this.$on("edit", async (aktivitet) => {
            await this.updateAktivitet(aktivitet);
        });
        this.$on("delete", (aktivitet) => {
            this.deleteAktivitetDialog(aktivitet);
        });
        this.$on("duplicate", (aktivitet) => {
            this.duplicateAktivitet(aktivitet);
        });
    },
};
</script>

<template>
    <div>
        <h2>
            Aktiviteter
            <hollow-button @click="addAktivitet()" :icon="PlusCircleBlue">Lägg till aktivitet</hollow-button>
        </h2>

        <FormulateForm class="filter" @submit="filter" v-model="formData">
            <div class="search">
                <FormulateInput
                    type="search"
                    name="search"
                    label="Sök aktivitet, lag, typ, plats, underlag, beskrivning"
                />
                <div>
                    <button role="button" type="submit" class="button button-primary">Sök</button>
                </div>
            </div>
            <div class="fields">
                <FormulateInput type="date" name="tidpunkt__date__gte" label="Från datum" />
                <FormulateInput type="date" name="tidpunkt__date__lte" label="Till datum" />
            </div>
        </FormulateForm>

        <custom-action-table :items="aktiviteter" :columns="TableColumns" :actions="TableActions" />

        <div class="next">
            Visar <strong>{{ aktiviteter.length }}</strong> av <strong>{{ count }}</strong> träffar

            <button class="load-next" :disabled="limit >= count" @click="loadMoreAktiviteter">
                Visa flera träffar
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/variables";

.pad {
    padding-top: 30px;
}

.filter {
    background-color: $color-background-puff;
    margin: 21px 0;
    padding: 22px;
    display: flex;
    flex-direction: column;
    gap: 17px;

    .search {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 17px;

        & > .formulate-input:first-child {
            flex-grow: 1;
        }
    }

    .fields {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 17px;
    }

    .button {
        border: none;
        border-radius: 4px;
        color: white;
        padding: 13px 22.5px 12px;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
    }

    .button-primary {
        background-color: $color-button-primary;
    }
}

.next {
    margin: 22px auto;
    text-align: center;
    color: $color-text-black;
    font-size: 16px;
    line-height: 21px;

    .load-next {
        margin: 22px auto;
        display: block;
        width: 60%;
        padding: 13px;
        border: 0;
        border-radius: 4px;
        color: white;
        background-color: $color-button-primary;

        &:disabled {
            opacity: $disabled-opacity;
        }
    }
}

::v-deep .action-table table td:first-child {
    border-right: none;
    font-weight: inherit;
    font-size: inherit;
}
</style>
