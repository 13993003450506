<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import tabIcon from "@/assets/narvarostatistik.svg";
import StatistikSearchBar from "@/tabs/Skreg/StatistikSearchBar.vue";
import KlinikenDropDownMenu from "@/components/Table/KlinikenDropDownMenu.vue";
import ActionIconOpanmalan from "@/assets/actionIcons/show.svg";
import CircularProgressBar from "@/components/CircularProgressBar.vue";
import SummaryTable from "@/tabs/Skreg/Statistik/Narvarostatistik/SummaryTable.vue";
import NarvarostatistikSpelare from "@/tabs/Skreg/Statistik/Narvarostatistik/NarvarostatistikSpelare.vue";
import NarvarostatistikTableRow from "@/tabs/Skreg/Statistik/NarvarostatistikTableRow.vue";
import { fetchData } from "@/tabs/Operation/utils.js";
import JournalAnteckningar from "@/tabs/Journalanteckningar/JournalAnteckningar.vue";
import JournalAnteckning from "@/tabs/Journalanteckningar/Journalanteckning.vue";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
import moment from "moment";
import IdrottPopup from "@/tabs/Skreg/Statistik/IdrottPopup.vue";

export default {
    extends: BaseTab,
    name: "NarvarostatistikLag",
    tabName: "Närvarostatistik lag",
    icon: tabIcon,

    components: {
        StatistikSearchBar,
        KlinikenDropDownMenu,
        CircularProgressBar,
        SummaryTable,
        NarvarostatistikSpelare,
        NarvarostatistikTableRow,
        JournalAnteckningar,
        JournalAnteckning,
        ModalBasic,
        BasicModalVariant,
        IdrottPopup,
    },

    data() {
        return {
            searchOptions: {},
            searchFilterValues: null,
            headers: [
                "Deltagare",
                "Totalt",
                "Totalt (h)",
                "Deltagit",
                "Deltagit (h)",
                "Frånvarande",
                "Frånvarande (h)",
                "Skadad",
                "Skadad (h)",
                "Frånvarande (%)",
                "Skadad (%)",
                "Status",
                "",
            ],
            rows: [],
            summaryTableData: {},
            toggleRow: false,
            visibleRows: [],
            dropdownActions: [
                {
                    title: "Närvarostatistik spelare",
                    name: "visanarvarostatistikspelare",
                    icon: ActionIconOpanmalan,
                    show: () => true,
                },
                {
                    title: "Journalanteckningar skadekort",
                    name: "visajournalanteckningarskadekort",
                    icon: ActionIconOpanmalan,
                    show: (item) => (item.data.sista_oavstamd_skadekort ? true : false),
                },
            ],
            lag: [],
            idrotter: [],
            idrott: "",
            BASE_URL: "/skreg/statistik/idrotter/",
            showNoDataMessag: false,
        };
    },

    methods: {
        showAllRows() {
            this.toggleRow = !this.toggleRow;
        },

        tableRowClicked(item) {
            if (item.patient) {
                this.selectedItem = item;
                this.$store.dispatch("patientData/loadFromBackend", {
                    id: item.patient,
                    failSilently: false,
                });
            }
        },

        async dropdownaction({ action, item }) {
            switch (action) {
                case "visanarvarostatistikspelare":
                    await this.$store.dispatch("tabs/closeTab", NarvarostatistikSpelare, { root: true });
                    await this.$store.dispatch("patientData/loadFromBackend", { id: item.spelare.patient.toString() });
                    await this.$store.dispatch("tabs/openTab", {
                        component: NarvarostatistikSpelare,
                        parameters: { ...item, idrott: this.idrott, pk: item.spelare.patient },
                    });
                    break;
                case "visajournalanteckningarskadekort":
                    let id = item.data.sista_oavstamd_skadekort;
                    await this.$store.dispatch("tabs/closeTab", JournalAnteckning, { root: true });
                    await this.$store.dispatch("journalanteckningar/getJournalanteckning", id);
                    let anteckning = this.$store.getters["journalanteckningar/anteckning"];
                    await this.$store.dispatch("patientData/loadFromBackend", { id: anteckning.patient.id });
                    await this.$store.dispatch("tabs/openTab", { component: JournalAnteckning });
                    break;
                default:
                    break;
            }
        },

        async setIdrotterOptions() {
            const array = this.lag.map((item) => item.idrott_display.namn);
            const _idrotter = array.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            for (var item = 0; item < _idrotter.length; item++) {
                if (_idrotter[item] !== "Alla lag") {
                    this.idrotter.push({
                        label: _idrotter[item],
                        value: _idrotter[item],
                    });
                }
            }
        },

        returnValueifNotUndefined(value) {
            return value !== undefined && value !== null && value !== "allalag" ? value : "";
        },

        async searchLagWithQueryParams() {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            let url_query_parameters = `?lag=${this.returnValueifNotUndefined(
                this.searchFilterValues?.dropdownOption
            )}&tidpunkt__date__gte=${this.returnValueifNotUndefined(
                this.searchFilterValues?.fromDate
            )}&tidpunkt__date__lte=${this.returnValueifNotUndefined(this.searchFilterValues?.toDate)}`;
            let _narvaroData;
            if (this.searchFilterValues.dropdownOption === "allalag") {
                const dialog_response = await this.showVarningsPopup({
                    cancelButton: "Avbryt",
                    okButton: "Ja, gå vidare",
                    title: "Du har valt att söka på Alla lag i din förening. Detta kan innebära att du visar medicinsk info för spelare du inte har en vårdrelation till. Vill du gå vidare med sökningen?",
                });
                if (dialog_response) {
                    _narvaroData = await this.getNarvaroData(url_query_parameters);
                } else {
                }
            } else {
                _narvaroData = await this.getNarvaroData(url_query_parameters);
            }
            if (_narvaroData.spelare_list.length) {
                this.showNoDataMessag = false;
            } else {
                this.showNoDataMessag = true;
            }
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
        filterByShirtNumber() {
            const filteredData = this.rows.filter(
                (item) =>
                    item.spelare &&
                    item.spelare.spelarelag &&
                    item.spelare.spelarelag[0] &&
                    item.spelare.spelarelag[0].trojnummer !== undefined
            );
            filteredData.sort((a, b) => a.spelare.spelarelag[0].trojnummer - b.spelare.spelarelag[0].trojnummer);
            this.rows = filteredData;
            return filteredData;
        },
        async showIfLeghthIsZero(array) {
            if (array.length == 0) {
                await this.showVarningsPopup({
                    okButton: "OK",
                    title: "Det finns inga avstämda aktiviteter i vald tidsperiod",
                });
            }
        },

        async getNarvaroData(url) {
            const _response = await fetchData({
                url: this.BASE_URL + this.idrott + "/narvaro/lag/" + this.returnValueifNotUndefined(url),
            });
            this.rows = _response.spelare_list;
            if (this.searchFilterValues.dropdownOption !== "allalag") {
                this.rows = this.rows.map((item) => {
                    const filteredSpelarelag = item.spelare.spelarelag.filter((spelarelagItem) => {
                        return spelarelagItem.lag === this.searchFilterValues.dropdownOption;
                    });

                    return {
                        ...item,
                        spelare: {
                            ...item.spelare,
                            spelarelag: filteredSpelarelag,
                        },
                    };
                });
            }
            this.filterByShirtNumber();

            this.summaryTableData = _response;
            return _response;
        },

        async getLagDropdown(idrott) {
            return await fetchData({
                url: `/skreg/lag/?idrott__namn=${this.returnValueifNotUndefined(idrott)}`,
            });
        },
        async showVarningsPopup({ okButton, cancelButton, title }) {
            const dialog_response = await this.$refs.dialog.show({
                variant: BasicModalVariant.WARNING,
                okButton: okButton,
                title: title,
                cancelButton: cancelButton && cancelButton,
            });
            return dialog_response;
        },

        async chooseIdrott(idrott) {
            this.lag = await this.getLagDropdown(idrott);
            this.idrott = idrott;
            this.lag.push({
                namn: "Alla lag",
                id: "allalag",
                idrott_display: {
                    id: "idrott_display_allalag",
                    namn: "Alla lag",
                },
            });
        },
    },

    async created() {
        const firstDate = moment().startOf("month");
        const lastDate = moment().endOf("month");
        this.lag = await this.getLagDropdown(this.idrott);

        this.lag.push({
            namn: "Alla lag",
            id: "allalag",
            idrott_display: {
                id: "idrott_display_allalag",
                namn: "Alla lag",
            },
        });
        this.searchFilterValues = {
            dropdownOptions: this.lag,
            fromDate: firstDate.format("YYYY-MM-DD"),
            toDate: lastDate.format("YYYY-MM-DD"),
        };
        await this.setIdrotterOptions();

        if (this.idrotter.length > 1) {
        } else {
            this.idrott = this.idrotter[0].value;
        }
    },
};
</script>

<template>
    <div class="narvarostatistik-lag">
        <h2>Närvarostatistik lag</h2>
        <StatistikSearchBar v-model="searchFilterValues" @emitValues="searchLagWithQueryParams" :lag="lag" />
        <SummaryTable v-if="rows.length" :summaryTableData="summaryTableData" class="summarytable" />
        <FormulateInput
            v-if="rows.length"
            name="showAll"
            type="checkbox"
            label="Visa alla rader"
            @change="showAllRows"
        />
        <table v-if="rows.length">
            <thead class="sticky-header">
                <tr>
                    <th class="" v-for="(header, index) in headers" :key="index">
                        <div
                            v-if="header === 'Deltagare'"
                            class="header-vertical-padding-8 heading-style align-left padding-left-20"
                        >
                            {{ header }}
                        </div>
                        <div v-else class="header-vertical-padding-8 heading-style align-right">
                            {{ header }}
                        </div>
                    </th>
                </tr>
            </thead>
            <NarvarostatistikTableRow
                v-for="(row, rowIndex) in rows"
                :key="rowIndex"
                :rowData="row"
                :dropdownActions="dropdownActions"
                :toggleRow="toggleRow"
                :toggleable="true"
                @action="dropdownaction"
                @tableRowClicked="tableRowClicked"
            />
        </table>
        <div v-if="showNoDataMessag" class="no-data">
            <img src="@/assets/check_circle_black.svg" alt="black circle" /> Det finns inga avstämda aktiviteter i vald
            tidsperiod.
        </div>
        <IdrottPopup :idrotter="idrotter" @chooseIdrott="chooseIdrott" />
        <modal-basic ref="dialog" />
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.narvarostatistik-lag {
    .formulate-input[data-type="checkbox"] {
        display: flex;
        justify-content: flex-end;
    }

    .summarytable {
        max-width: 50rem;
    }

    .align-right {
        text-align: right;
    }

    .align-left {
        text-align: left;
    }

    .header-vertical-padding-8 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .padding-left-20 {
        padding-left: 1.25rem;
    }

    .no-data {
        img {
            height: 20px;
            margin-right: 10px;
        }

        display: flex;
    }

    table {
        border-collapse: collapse;
        width: 100%;

        thead.sticky-header {
            background-color: $color-bg-table-header;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1;

            .heading-style {
                color: $color-table-header;
                font-family: Roboto Medium;
                font-weight: 500;
                line-height: 17px;
                font-size: 14px;
            }
        }
    }

    margin-bottom: 5rem;
}
</style>
